import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Box from "../components/Box/Box";
import GridSection from "../components/GridSection";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import {
  Paragraph,
  Paragraph2,
  Subtitle,
  Title,
  Listul,
  Listli,
} from "../components/Styled/Text";
import Wrapper from "../components/Wrapper";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { PolicyQuery } from "../types/pages/policy";

interface Props extends PageProps {
  data: PolicyQuery;
}

const Policy = ({ data, location }: Props) => {
  const { width, height } = useWindowSize();
  return (
    <>
      <Seo
        title={data.translationsJson.pages.policy.seo.title}
        description={data.translationsJson.pages.policy.seo.description}
        keywords={data.translationsJson.pages.policy.seo.keywords}
        pathname={location.pathname}
      />
      <Layout pathname={location.pathname} menuInitialColor="rgb(184,171,148)">
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#000",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            {data.translationsJson.pages.policy.header.title.map((t, i) => (
              <Title variant="bigHero" key={i} sx={{ color: "#000" }}>
                {t}
              </Title>
            ))}
            <Paragraph2
              variant="hero"
              sx={{
                color: "#000",
                opacity: 0.85,
                textAlign: ["center", "center", "center", "center"],
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.policy.header.description}
            </Paragraph2>
          </Box>
        </Box>
        <Wrapper
          sx={{
            marginBottom: "40px",
          }}
        >
          {data.translationsJson.pages.policy.section.text.map((t, i) => (
            <Paragraph
              key={i}
              sx={{
                textAlign: "left",
                alignSelf: "center",
                width: "100%",
              }}
            >
              {t}
            </Paragraph>
          ))}

          <Listul
            sx={{
              marginBottom: "40px",
            }}
          >
            {data.translationsJson.pages.policy.section.list1.map((t, i) => (
              <Listli
                key={i}
                sx={{
                  textAlign: "left",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {t}
              </Listli>
            ))}
          </Listul>

          {data.translationsJson.pages.policy.section.text1.map((t, i) => (
            <Paragraph
              key={i}
              sx={{
                textAlign: "left",
                alignSelf: "center",
                width: "100%",
              }}
            >
              {t}
            </Paragraph>
          ))}
          <Listul
            sx={{
              marginBottom: "40px",
            }}
          >
            {data.translationsJson.pages.policy.section.list4.map((t, i) => (
              <Listli
                key={i}
                sx={{
                  textAlign: "left",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {t}
              </Listli>
            ))}
          </Listul>

          {data.translationsJson.pages.policy.section.text4.map((t, i) => (
            <Paragraph
              key={i}
              sx={{
                textAlign: "left",
                alignSelf: "center",
                width: "100%",
              }}
            >
              {t}
            </Paragraph>
          ))}
          <Listul
            sx={{
              marginBottom: "40px",
            }}
          >
            {data.translationsJson.pages.policy.section.list6.map((t, i) => (
              <Listli
                key={i}
                sx={{
                  textAlign: "left",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {t}
              </Listli>
            ))}
          </Listul>

          {data.translationsJson.pages.policy.section.text7.map((t, i) => (
            <Paragraph
              key={i}
              sx={{
                textAlign: "left",
                alignSelf: "center",
                width: "100%",
              }}
            >
              {t}
            </Paragraph>
          ))}

          <Listul
            sx={{
              marginBottom: "40px",
            }}
          >
            {data.translationsJson.pages.policy.section.list71.map((t, i) => (
              <Listli
                key={i}
                sx={{
                  textAlign: "left",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {t}
              </Listli>
            ))}
          </Listul>

          {data.translationsJson.pages.policy.section.text72.map((t, i) => (
            <Paragraph
              key={i}
              sx={{
                textAlign: "left",
                alignSelf: "center",
                width: "100%",
              }}
            >
              {t}
            </Paragraph>
          ))}
        </Wrapper>
      </Layout>
    </>
  );
};

export default React.memo(Policy);

export const policyQuery = graphql`
  query PolicyQuery {
    translationsJson {
      pages {
        policy {
          seo {
            title
            description
            keywords
          }
          header {
            title
            description
          }
          section {
            text
            list1
            text1
            list4
            text4
            list6
            text7
            list71
            text72
          }
        }
      }
    }
    site {
      siteMetadata {
        address
        addressLink
        email
        phone
      }
    }
  }
`;
